import { dependencies } from "./Dependencies";

const merchantId = dependencies.getFromLocalStorage("id");
const fullName = dependencies.getFromLocalStorage("MerchantName");
let firstName, lastName;
if (fullName) {
  [firstName, lastName] = fullName.split(" ");
}
const email = dependencies.getFromLocalStorage("Email");
const phoneNumber = dependencies.getFromLocalStorage("Phone");

export const addDjangoLangToCookies = lang => {
  if (window.location.hostname == "acceptstagingtest.paymobsolutions.com") {
    document.cookie = `django_language=${lang}; path=/; Domain=.paymobsolutions.com`;
  } else {
    document.cookie = `django_language=${lang}; path=/; Domain=.paymob.com`;
  }
};

let observerCleanup = null; 
export const setFreshchatPropertiesObserver = () => {
  let hasSetProperties = false;

  const setUserProperties = () => {
    const userInfo = {
      externalId: merchantId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber,
    };

    if (window.fcWidget && window.fcWidget.user) {
      window.fcWidget.user.setProperties(userInfo);
      hasSetProperties = true;
    }
  };

  const observerCallback = () => {
    if (!hasSetProperties && document.querySelector('#fc_frame')) {
      setUserProperties();
    }
  };

  const observer = new MutationObserver(observerCallback);
  observer.observe(document.body, { childList: true, subtree: true });

  observerCleanup = () => {
    observer.disconnect();
  };
};

export const cleanupFreshchatObserver = () => {
  if (observerCleanup) {
    observerCleanup();
    observerCleanup = null; 
  }
};

export const hideChatWidgets = (supportWidget, chatWidget) => {
  const hide = () => {
    supportWidget && supportWidget("hide");
    chatWidget &&
      chatWidget({
        headerProperty: {
          hideChatButton: true,
        },
      });
  };

  hide();
  const observer = new MutationObserver(hide);
  observer.observe(document.body, { childList: true, subtree: true });

  const cleanup = () => {
    supportWidget && supportWidget("show");
    chatWidget &&
      chatWidget({
        headerProperty: {
          hideChatButton: false,
        },
      });
    observer.disconnect();
  };

  return cleanup;
};
